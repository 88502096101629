.about-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
}

.animated-text {
	font-size: 2em;
	font-weight: bold;
	background: linear-gradient(
		45deg,
		#ffb6c1,
		#ffd700,
		#98fb98,
		#00ffff,
		#87ceeb,
		#9370db,
		#ff69b4,
		#ffa500,
		#ba55d3,
		#00fa9a
	);
	background-size: 400% 400%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: gradientAnimation 15s infinite;
}

.about-text {
	max-width: 600px;
	text-align: center;
	margin-top: 10px;
	color: #333;
}

@keyframes gradientAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* Media Queries */
@media (max-width: 600px) {
	.about-text {
		font-size: 13px; /* Adjust the font size as needed */
	}
}

body {
	font-family: "Mochiy Pop One", sans-serif;
	text-align: center;
	background-color: #fafafa; /* Off-white background color */
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	overflow-x: hidden;
}

#container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px; /* Add padding to the container for small screens */
	gap: 15px;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 500ms;
}

#links button {
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	color: #333;
	padding: 10px 20px;
	border-radius: 5px;
	transition: color 0.3s ease, background-color 0.3s ease; /* Adjust for background color transitions */
	font-family: "Mochiy Pop One", sans-serif; /* Ensuring font carries over */
	font-size: 18px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#links button:not(#complaints) {
	letter-spacing: 3.5px;
}

#links button[onClick*="games"] {
	background: linear-gradient(90deg, #333, #e53f66, #333, #e53f66, #333);
	background-size: 200% auto;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	animation: gradientShift 5s infinite;
}

#links button[onClick*="games"] span {
	color: inherit;
}

#links {
	display: flex;
	flex-direction: column;
	gap: 0px;
}

#links a {
	text-decoration: none;
	color: #333;
	padding: 10px 20px;
	border-radius: 5px;
	transition: color 0.3s ease, color 0.3s ease; /* Added color to the transition */
}

.bouncy {
	display: inline-block;
	cursor: pointer;
}

#links button:hover:not([onClick*="games"]) {
	color: #e53f66;
}

.bouncy span {
	display: inline-block;
	vertical-align: middle; /* Ensure consistent vertical alignment */
	transform: translateY(0); /* Set a default transformation */
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	margin-right: -4px; /* Adjust spacing between letters */
	animation-name: none; /* No animation by default */
}

.bouncy:hover span {
	animation-name: waviy;
	animation-delay: calc(
		0.1s * var(--i)
	); /* Staggered delay based on the --i variable */
}

@keyframes waviy {
	0%,
	40%,
	100% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-5px);
	}
}

@keyframes gradientShift {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#links a[href="games.html"] {
	display: inline-block; /* This allows the gradient to span across the entire element */
	background: linear-gradient(
		90deg,
		#333,
		#e53f66,
		#333,
		#e53f66,
		#333
	); /* More contrasting colors */
	background-size: 200% auto;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	animation: gradientShift 5s infinite;
}

#links a[href="games.html"] span {
	color: inherit; /* Ensures that the spans inherit the transparent color, allowing the gradient to be seen */
}

@keyframes wobble {
	0%,
	100% {
		transform: rotate(0deg);
	}
	15% {
		transform: rotate(-2deg);
	}
	30% {
		transform: rotate(2deg);
	}
	45% {
		transform: rotate(-5deg);
	}
	60% {
		transform: rotate(5deg);
	}
	75% {
		transform: rotate(-5deg);
	}
}

#header img:hover {
	animation: wobble 0.8s ease-in-out;
}

@keyframes tweak {
	0%,
	100% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(10deg);
	}
	75% {
		transform: rotate(-10deg);
	}
}

#social-links a:hover {
	animation: tweak 3s infinite;
}

#complaints {
	position: fixed;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	background-color: transparent;
	color: #333;
	border: none;
	cursor: pointer;
	z-index: 1000;
}

@keyframes unhingeAndFall {
	0% {
		transform: translateX(-50%) rotate(0deg); /* Keep it centered */
		opacity: 1;
	}
	10% {
		transform: translateX(-50%) rotate(15deg);
	}
	20% {
		transform: translateX(-50%) rotate(-10deg);
	}
	30% {
		transform: translateX(-50%) rotate(8deg);
	}
	100% {
		transform: translateX(-50%) translateY(100vh) rotate(15deg);
		opacity: 0.5;
	}
}

.crashing {
	position: fixed; /* Ensure it doesn't affect the page layout */
	animation: unhingeAndFall 2s forwards; /* Use the animation */
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-3px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(3px, 0, 0);
	}
}

.shake-screen {
	animation: shake 0.5s;
}

@media (max-width: 600px) and (max-height: 800px) {
	#container {
		padding: 20px; /* Add padding to the container for small screens */
		gap: 15px; /* Adjust the gap */
	}

	#banner img {
		width: 64px; /* Smaller logo for mobile */
	}

	#links {
		gap: 0px; /* Smaller gap between links */
	}

	#links button {
		padding: 8px 16px; /* Adjusted padding for links */
	}
}

#social-links {
	display: flex;
	gap: 20px; /* Adjust this value for spacing between icons */
	justify-content: center;
	margin-top: 20px; /* Spacing from the above links */
}

#social-links a {
	color: #333; /* Initial color for icons, can be adjusted */
	font-size: 24px; /* Size of icons, can be adjusted */
	transition: color 0.3s ease; /* Smooth color transition on hover */
}

#social-links a:hover {
	color: #e53f66; /* Color on hover, can be adjusted */
}

/* Grid container */
.game-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
	gap: 20px; /* Space between cards */
	padding: 20px; /* Padding around the grid */
}

/* Individual game card */
.game-card {
	width: 300px; /* Set a fixed width for the card */
	background-color: #fff;
	border-radius: 15px;
	overflow: hidden;
	transition: transform 0.3s ease;
}

.game-card:hover {
	transform: scale(1.05);
}

.game-card img {
	width: 100%;
	display: block;
}

/* Media Queries to adjust grid for various screen sizes */

/* For screens smaller than 1000px but larger than 600px, allow 2 cards per row */
@media screen and (max-width: 1000px) and (min-width: 601px) {
	.game-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* For very small screens (<= 600px), allow just 1 card per row */
@media screen and (max-width: 600px) {
	.game-grid {
		grid-template-columns: 1fr;
	}
}

.game-card.hidden {
	opacity: 0;
}

.fadeIn {
	animation: fadeInEffect 1s forwards;
}

@keyframes fadeInEffect {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

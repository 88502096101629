.back-button {
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: none;
	color: #333;
	padding: 10px 20px;
	border-radius: 5px;
	transition: color 0.3s ease;
	font-family: "Mochiy Pop One", sans-serif;
	display: inline-block;
	letter-spacing: 3.5px;
	font-size: 18px;
}

.back-button:hover {
	color: #e53f66;
}

.back-button span {
	display: inline-block;
	vertical-align: middle;
	transform: translateY(0);
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	margin-right: -4px;
	animation-name: none;
}

.back-button:hover span {
	animation-name: waviy;
	animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
	0%,
	40%,
	100% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-5px);
	}
}

.contact-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

h1 {
	font-size: 1.5em;
	margin-bottom: 20px;
}

.contact-form {
	display: flex;
	flex-direction: column;
	width: 300px;
}

input,
textarea {
	margin-bottom: 15px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 1em;
	outline: none;
}

.submit-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.submit-button:hover {
	background-color: #0056b3;
}

.thank-you-message {
	color: green;
	font-size: 1.2em;
}
